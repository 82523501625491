
export default {
    name: 'TextFormatting',
    props: {
        parentModelName: {
            type: String,
            default: '',
        },
        modelName: {
            type: String,
            default: '',
        },
        propName: {
            type: String,
            default: '',
        },
        justColPlus: {
            type: Boolean,
            default: false,
        },
        justContactProps: {
            type: Boolean,
            default: true,
        },
        fields: {
            type: Object,
            default: null,
        },
    },
    data () {
        return {
            menu: false,
            textHtmlElement: null,
            context: null,
        }
    },
    watch: {
    },
    computed: {
        contactFields () {
            if (this.fields) {
                return this.fields.fields
            }
            return [
                { value: 'firstname', text: this.$t('Nombre') },
                { value: 'lastname', text: this.$t('Apellidos') },
                { value: 'email', text: this.$t('Email') },
                { value: 'phone', text: this.$t('Número de telefono') },
            ]
        },
    },
    methods: {
        setPropertyValueWithParent (before, after, col) {
            this.context[this.parentModelName][this.modelName][this.propName] = ''
            if (before.length) {
                this.context[this.parentModelName][this.modelName][this.propName] = before
            }
            this.context[this.parentModelName][this.modelName][this.propName] += col
            let cursorPosition = this.context[this.parentModelName][this.modelName][this.propName].length
            if (after.length) {
              this.context[this.parentModelName][this.modelName][this.propName] += after
              cursorPosition--
            }
            return cursorPosition
        },

        setPropertyValue (before, after, col) {
            this.context[this.modelName][this.propName] = ''
            if (before.length) {
                this.context[this.modelName][this.propName] = before
            }
            this.context[this.modelName][this.propName] += col
            let cursorPosition = this.context[this.modelName][this.propName].length
            if (after.length) {
              this.context[this.modelName][this.propName] += after
              cursorPosition--
            }
            return cursorPosition
        },

        addCustomFieldToMessage () {
            const textarea = this.textHtmlElement
            const sentence = textarea.value
            const len = sentence.length
            let pos = textarea.selectionStart
            if (pos === undefined) {
              pos = 0
            }

            const before = sentence.substr(0, pos)
            const after = sentence.substr(pos, len)

            let col = '{COL+}'
            if (before.length && before.charAt(before.length - 1) !== ' ') {
              col = ' ' + col
            }
            if (after.length && after.charAt(0) !== ' ') {
              col += ' '
            }

            const cursorPosition = this.parentModelName === '' ? this.setPropertyValue(before, after, col) : this.setPropertyValueWithParent(before, after, col)

            this.$nextTick().then(() => {
              textarea.selectionStart = cursorPosition
              textarea.selectionEnd = cursorPosition
              textarea.click()
              this.$emit('updated-custom-field')
            })
          },
        setContext (context, textHtmlElement) {
            this.context = context
            this.textHtmlElement = textHtmlElement
        },
        // eslint-disable-next-line complexity
        addFormatText (type) {
            const message = this.textHtmlElement
            const from = message.selectionStart
            const to = message.selectionEnd
            const textSelected = message.value.substring(from, to)
            let text = null
            switch (type) {
                case 'strong':
                    text = this.addHtmlTag(message, from, to, textSelected, '*', '*')
                    break
                case 'italic':
                    text = this.addHtmlTag(message, from, to, textSelected, '_', '_')
                    break
                case 'strike':
                    text = this.addHtmlTag(message, from, to, textSelected, '~', '~')
                    break
                case 'monospace':
                    text = this.addHtmlTag(message, from, to, textSelected, '```', '```')
                    break
                case 'list':
                    text = this.addListPrefix(message, from, to, textSelected, '-')
                    break
                case 'numberedList':
                    text = this.addNumberedListPrefix(message, from, to, textSelected)
                    break
                case 'quote':
                    text = this.AddQuotePrefix(message, from, to, textSelected, '>')
                    break
                case 'aligned':
                    text = this.addHtmlTag(message, from, to, textSelected, '`', '`')
                    break
            }
            if (text) {
                if (this.parentModelName !== '') {
                    this.context[this.parentModelName][this.modelName][this.propName] = text
                } else {
                    this.context[this.modelName][this.propName] = text
                }
            }
        },
        AddQuotePrefix (message, from, to, textSelected, firstTag) {
            if (textSelected.length > 0) {
                message.setRangeText(`${firstTag} ${textSelected}`, from, to, 'select')
                return message.value
            }
            return null
        },
        addNumberedListPrefix (message, from, to, textSelected) {
            if (textSelected.length > 0) {
                const tmp = textSelected.split('\n')
                const res = []
                let count = 1
                for (const frag of tmp) {
                    res.push(`${count}. ${frag}`)
                    count++
                }

                const replaced = res.join('\n')
                message.setRangeText(replaced, from, to, 'select')
                return message.value
            }
            return null
        },
        addListPrefix (message, from, to, textSelected, firstTag) {
            if (textSelected.length > 0) {
                const tmp = textSelected.split('\n')
                const res = []

                for (const frag of tmp) {
                    res.push(`${firstTag} ${frag}`)
                }

                const replaced = res.join('\n')
                message.setRangeText(replaced, from, to, 'select')
                return message.value
            }
            return null
        },
        addHtmlTag (message, from, to, textSelected, tagOpen, tagClose) {
            if (textSelected.length > 0) {
                message.setRangeText(`${tagOpen}${textSelected}${tagClose}`, from, to, 'select')
                return message.value
            }
            return null
        },
        insertFieldAtCursor (myValue) {
            const textarea = this.textHtmlElement
            const sentence = textarea.value
            if (!myValue.length) return

            const len = sentence.length
            let pos = textarea.selectionStart
            if (pos === undefined) {
                pos = 0
            }

            const before = sentence.substr(0, pos)
            const after = sentence.substr(pos, len)
            const newValue = before + `{${myValue}}` + after

            if (newValue) {
                if (this.parentModelName !== '') {
                    this.context[this.parentModelName][this.modelName][this.propName] = newValue
                } else {
                    this.context[this.modelName][this.propName] = newValue
                }
            }
            this.$nextTick().then(() => {
                textarea.selectionStart = pos + myValue.length + 2
                textarea.selectionEnd = pos + myValue.length + 2
                textarea.click()
            })
        },
    },
}
