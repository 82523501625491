var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-btn-toggle',{staticStyle:{"width":"100% !important"}},[(_vm.justColPlus)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"btn-action-whatsapp-sender",staticStyle:{"width":"11.11% !important","border-bottom-left-radius":"inherit","border-left-width":"1px"},attrs:{"small":"","color":"white"},on:{"click":_vm.addCustomFieldToMessage}},'v-btn',attrs,false),on),[_c('span',{staticClass:"ico-mini mr-2"},[_vm._v("{ } ")])])]}}],null,false,2416979016)},[_c('span',[_vm._v(_vm._s(_vm.$t('Campos personalizados')))])]):_vm._e(),(_vm.justContactProps)?_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onMenu = ref.on;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"btn-action-whatsapp-sender",staticStyle:{"width":"11.11% !important","border-bottom-left-radius":"inherit","border-left-width":"1px"},attrs:{"small":"","color":"white"}},'v-btn',attrs,false),Object.assign({}, onMenu, on)),[_c('span',{staticClass:"ico-mini mr-2"},[_vm._v("{ } ")])])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('Campos personalizados')))])])]}}],null,false,2607728456)},[_c('v-list',[_c('v-subheader',{staticClass:"selector-header"},[_vm._v(" "+_vm._s(_vm.$t('Campos personalizados'))+" ")]),_c('v-virtual-scroll',{attrs:{"items":_vm.contactFields,"height":"250","item-height":"45"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_c('v-list-item',{key:'list-custom-field-header' + item.value,staticClass:"cursor-pointer",on:{"click":function($event){return _vm.insertFieldAtCursor(item.value)}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(item.text)+" ")])],1)],1)]}}],null,false,2795859717)})],1)],1):_vm._e(),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"btn-action-whatsapp-sender border-top-r-radius-0",attrs:{"small":"","color":"white"},on:{"click":function($event){return _vm.addFormatText('strong')}}},'v-btn',attrs,false),on),[_c('i',{staticClass:"fas fa-bold pr-2 ico-mini"})])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('Negrita')))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"btn-action-whatsapp-sender border-top-r-radius-0",attrs:{"small":"","color":"white"},on:{"click":function($event){return _vm.addFormatText('italic')}}},'v-btn',attrs,false),on),[_c('i',{staticClass:"fas fa-italic pr-2 ico-mini"})])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('Cursiva')))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"btn-action-whatsapp-sender border-top-r-radius-0",attrs:{"small":"","color":"white"},on:{"click":function($event){return _vm.addFormatText('strike')}}},'v-btn',attrs,false),on),[_c('i',{staticClass:"fas fa-strikethrough pr-2 ico-mini"})])]}}])},[_c('strike',[_vm._v(_vm._s(_vm.$t('Tachado')))])],1),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"btn-action-whatsapp-sender border-top-r-radius-0",attrs:{"small":"","color":"white"},on:{"click":function($event){return _vm.addFormatText('list')}}},'v-btn',attrs,false),on),[_c('i',{staticClass:"fas fa-list pr-2 ico-mini"})])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('lista')))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"btn-action-whatsapp-sender border-top-r-radius-0",attrs:{"small":"","color":"white"},on:{"click":function($event){return _vm.addFormatText('numberedList')}}},'v-btn',attrs,false),on),[_c('i',{staticClass:"fas fa-list-ol pr-2 ico-mini"})])]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('lista'))+" ")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"btn-action-whatsapp-sender border-top-r-radius-0",attrs:{"small":"","color":"white"},on:{"click":function($event){return _vm.addFormatText('quote')}}},'v-btn',attrs,false),on),[_c('i',{staticClass:"fas fa-quote-left pr-2 ico-mini"})])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('Cita')))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"btn-action-whatsapp-sender border-top-r-radius-0",attrs:{"small":"","color":"white"},on:{"click":function($event){return _vm.addFormatText('aligned')}}},'v-btn',attrs,false),on),[_c('i',{staticClass:"fa-solid fa-angle-left"}),_c('i',{staticClass:"fas fa-ellipsis ico-mini"}),_c('i',{staticClass:"fa-solid fa-angle-right"})])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('Código alineado')))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"btn-action-whatsapp-sender border-top-r-radius-0",attrs:{"small":"","color":"white"},on:{"click":function($event){return _vm.addFormatText('monospace')}}},'v-btn',attrs,false),on),[_vm._v(" </> ")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('Monoespacio')))])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }