const CALL_TO_ACTION = 1
const RESPONSE_AUTOMATIC = 2

export const TYPE_OPTION_BUTTON = [
  { value: 1, icon: 'fas fa-external-link-alt', text: 'Enlace externo', type: CALL_TO_ACTION },
  { value: 2, icon: 'fa fa-phone', text: 'Número de teléfono', type: CALL_TO_ACTION },
  { value: 3, icon: 'far fa-clone', text: 'Copiar código', type: CALL_TO_ACTION },
  { value: 4, icon: 'fa fa-reply', text: 'Respuesta automática', type: RESPONSE_AUTOMATIC },
  { value: 5, icon: 'fa fa-reply', text: 'Desactivar marketing', type: RESPONSE_AUTOMATIC },
]
