export class CurrentWhatsapp {
  encoding
  maxCharacters
  characters
  chunks
  maxCharactersInfo
  hasCustomFields

  constructor (encoding, maxCharacters = 1024, characters = 0, chunks, hasCustomFields = false) {
    this.encoding = encoding
    this.maxCharacters = maxCharacters
    this.characters = characters
    this.chunks = chunks
    this.hasCustomFields = false
  }

  static whatsappParserResultToCurrentWhatsapp (encoding, text, maxCharacters, chunks) {
    const totalCharactersUsed = text.length
    // const totalChunks = totalCharactersUsed > maxCharacters ? chunks + 1 : chunks
    const totalChunks = 1
    // const totalMaxCharacters = totalCharactersUsed > maxCharacters ? maxCharacters * totalChunks : maxCharacters
    const totalMaxCharacters = 1024
    return new CurrentWhatsapp(encoding, totalMaxCharacters, totalCharactersUsed, totalChunks)
  }
}
