
import { Service } from '@/services/service'

const PATH = 'whatsapp/fixed-messages/'

class FixedMessagesService extends Service {
  getByIsoCode (params) {
    return this.post(PATH + 'by-iso-code', params)
  }
}

export default new FixedMessagesService()
