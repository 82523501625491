<template>
  <div>
    <header-top-dashboard neutral />
    <v-container
      fluid
      class="pt-0 px-6 mt-n16 container-general"
    >
      <v-row>
        <v-col
          cols="12"
          class="mt-n3"
        >
          <whatsapp-editor />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  import HeaderTopDashboard from '@/components/Layout/HeaderTopDashboard.vue'
  import WhatsappEditor from '@/components/Template/Whatsapp/WhatsappEditor.vue'

  export default {
    name: 'CreateWhatsappTemplate',
    components: {
      HeaderTopDashboard,
      WhatsappEditor,
    },
  }
</script>

<style scoped>

</style>
