export default {
  name: 'NewOptionButton',
  components: {},
  props: {
    typeOptionButton: {
      require: true,
      type: Object,
    },
  },
  data () {
    return {}
  },
  methods: {
    addTypeOptionButton (value) {
      this.$emit('addTypeOptionButton', value)
    },
  },
}
