export class FixedMessageAuth {
  id = null
  isoCode = null
  authFooterCodeExpiration = null
  authBodyMessage = null
  authBodyRecommendation = null
  authButtonAutofill = null
  authButtonCodeCopy = null

  constructor (data) {
    this.loadData(data)
  }

  loadData (data) {
    if (!data) return
    this.id = data.id
    this.isoCode = data.iso_code
    this.authFooterCodeExpiration = data.auth_footer_code_expiration
    this.authBodyMessage = data.auth_body_message
    this.authBodyRecommendation = data.auth_body_recommendation
    this.authButtonAutofill = data.auth_button_autofill
    this.authButtonCodeCopy = data.auth_button_code_copy
  }
}
